<template>
	<v-sheet v-if="project">
		<h4>{{project.internal_name}}</h4>
		<v-text-field
			input-type="number"
			v-model="project.incentive"
			label="Incentive"
		>
		</v-text-field>
		<v-text-field
			v-model="project.topic"
			label="Topic"
		>
		</v-text-field>
		<v-textarea
			v-model="project.other_info"
			label="Other Info"
		>
		</v-textarea>
		<p class="text-right">
			<v-btn @click="save" color="primary">Save</v-btn>
		</p>
		<secondary-navigation show-back>
            <template v-slot:main>
				<nav-item
                    link="/templates"
					text="Email Templates"
					icon="mdi-email"
				></nav-item>
            </template>
			<template v-slot:append>
				<edit-project-notes v-model="id"></edit-project-notes>
			</template>
        </secondary-navigation>

	</v-sheet>
</template>

<script>
import SecondaryNavigation from "@c/navigation/Secondary";
import EditProjectNotes from '@c/edits/EditProjectNotes';

import NavItem from "@/components/navigation/Item";
export default {
	name: "EditProjectEmailFields",
	props: {
		id: { type: String }, // @doc the id string of the object - will add a new document if not passed
    },
    computed: {
		project() {
			return this.$store.state.projects.data[this.id];
		},
	},
	components: {
		SecondaryNavigation,
        NavItem,
		EditProjectNotes
	},
	methods: {
		save() {
			let doc = {
				id: this.id,
			}
			if( this.project.incentive ){
				doc.incentive = this.project.incentive;
			}
			if( this.project.topic ){
				doc.topic = this.project.topic;
			}
			if( this.project.other_info ){
				doc.other_info = this.project.other_info;
			}
			
			this.$store.dispatch("projects/patch", doc).then( () => {
				this.$root.$emit("showToast", "Saved")
			});
		},
	},
	created() {
		this.$store.dispatch("projects/fetchById", this.id);
    }
};
</script>
